import styles from './PageContent.module.scss';

export const PageContentComponentId = 'PageContentComponent';

export interface IPageContentProps {
    withoutMargins?: boolean;
    children: any;
}

export function PageContent(props: IPageContentProps) {
    return (<div id={PageContentComponentId} className={styles.host} style={props.withoutMargins ? { margin: 0 } : undefined}>{props.children}</div>);
}
