import styles from './ArrayEditorInner.module.scss';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import i18n from 'src/Locale/i18n';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { ICrmArrayElement } from "@core/Models/i-array-element";
import Logger from "js-logger";
import { ConfirmDelete } from "@core/VisualComponents/ConfirmDelete/ConfirmDelete";
import { DateInput } from "@core/VisualComponents/Inputs/DateInput/DateInput";
import { StringInput } from "@core/VisualComponents/Inputs/StringInput/StringInput";
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { ComboboxInput } from '@core/VisualComponents/Inputs/ComboboxInput/ComboboxInput';
import { CheckboxInput } from '@core/VisualComponents/Inputs/CheckboxInput/CheckboxInput';
import { TimeInput } from '@core/VisualComponents/Inputs/TimeInput/TimeInput';
import { DecimalInput } from '@core/VisualComponents/Inputs/DecimalInput/DecimalInput';
import { InputValidator } from '@core/VisualComponents/Inputs/InputValidator/InputValidator';
import { InvalidTypeInput } from '@core/VisualComponents/Inputs/InvalidTypeInput/InvalidTypeInput';
import { UrlInput } from '@core/VisualComponents/Inputs/UrlInput/UrlInput';
import { ArrayValueRenderer } from '../ArrayValuesViewer/ArrayValuesViewer';
import { ReactComponent as ChevronDownIcon } from "@assets/Icons/chevron-down-icon.svg";
import { ReactComponent as ChevronUpIcon } from "@assets/Icons/chevron-up-icon.svg";
import { ReactComponent as TrashCanOutlineIcon } from "@assets/Icons/trash-can-outline-icon.svg";
import { PhoneInput } from '@core/VisualComponents/Inputs/PhoneInput/PhoneInput';
import { useAutocompleteStore } from '@core/Stores/AutocompleteStoreProvider';
import { ICrmUserInfo } from '@core/Models/autogenerated/user.models';
import { useAppSelector } from '@core/Redux/hooks';
import { selectUserInfo } from '@core/Redux/store';
import { getDefaultValue } from '@core/Helpers/getDefaultValue';
import { ICrmField, CrmFieldViewType } from '@core/Models/autogenerated/tenantConfig.models';
import { TagsInput } from '@core/VisualComponents/Inputs/TagsInput/TagsInput';
import { MongoQueryParser, NowReference, QueryPredicate } from '@core/Utils/MongoQueryParser';
import { createQueryVars } from '@core/Stores/QueryVars';
import { t } from 'i18next';
import { getUserTimeZone } from '@core/Helpers/js-date-transformations';

interface ICrmArrayEdititingElement extends ICrmArrayElement {
    _isNew?: boolean;
}

export interface IArrayEditorInnerProps {
    initialValues: any;
    onChanged: (v: ICrmArrayElement[]) => void;
    setIsValid?: (isValid: boolean) => void;
    saveIfInvalid: boolean;
    readonly?: boolean;
    tableId: string;
    field: ICrmField;
    reversed?: boolean;
}

export const ArrayEditorInner = registerComponent(coreUiComponentDescriptions.ArrayEditorInner, _ArrayEditorInner);

function _ArrayEditorInner(props: IArrayEditorInnerProps) {
    const userInfo = useAppSelector(selectUserInfo)!;
    const [anchorRef, setAnchorRef] = useState<MutableRefObject<any> | null>(null);
    const [deleteData, setDeleteData] = useState<{ id: string } | null>();
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState<boolean>(false);
    const [invalidInputs, setInvalidInputs] = useState<{id: string, fieldId: string}[]>([]);
    const [values, setValues] = useState<ICrmArrayEdititingElement[]>(getArrayValues(props.initialValues));
    const [autocompleteValues, setAutocompleteValues] = useState<Record<string, string[]>>();
    const [showHidden, setShowHidden] = useState<boolean>(false);
    const [filteredIds, setFilteredIds] = useState<string[]>([]);
    const autocompleteStore = useAutocompleteStore(props.tableId);
    const filteredFields = props.field.fields?.filter(x => !x.hidden);

    const loadAutocompleteValues = async () => {
        const newAutocompleteValues: Record<string, string[]> = {};
        
        for (const field of filteredFields || []) {
            if (!field.autocomplete) {
                continue;
            }

            const cachedValues = await autocompleteStore.getCachedValuesFromDumbCrmDb(props.field.id + "." + field.id);

            // Merge autocomplete values ​​with current values ​​in array for correct autocomplete list
            newAutocompleteValues[field.id] = Array.from(
                new Set([
                    ...cachedValues,
                    ...values.filter(x => x != null && (x as any)[field.id] != null && typeof (x as any)[field.id] == "string").map(value => (value as any)[field.id].trim() as string),
                ])
            );
        }

        setAutocompleteValues(newAutocompleteValues);
    };

    useEffect(() => {
        loadAutocompleteValues();
    }, [values]);

    const filterPredicate = useMemo(
        () => {
            if (props.field.filters?.[0] == null) {
                // TODO: for tabs change false => true
                return ((record: any) => [false, Infinity]) as QueryPredicate;
            }

            return new MongoQueryParser(true, createQueryVars(userInfo)).find(props.field.filters[0].where, new NowReference(getUserTimeZone()));
        },
        [props.field.filters, userInfo]
    );

    useEffect(() => {
        // TODO: for tabs remove !
        setFilteredIds(values.filter(v => !filterPredicate(v)[0]).map(x => x.id));
    }, [filterPredicate, showHidden]);

    const onValueChanged = (newValues: ICrmArrayEdititingElement[]) => {
        const valuesForSave = newValues
            .filter(value => !isEmptyValue(value))
            .map(value => {
                if (value._isNew) {
                    return {
                        ...value,
                        _isNew: undefined,
                    };
                }
                return value;
            }) as ICrmArrayElement[];
        if (props.initialValues != null || valuesForSave.length > 0) {
            props.onChanged(valuesForSave);
        }
    }

    const isEmptyValue = (value: ICrmArrayEdititingElement) => {
        for (const field of props.field.fields || []) {
            if (field.id in value && (value as any)[field.id] != null && (value as any)[field.id] !== "") {
                return false;
            }
        }
        return true;
    }

    const onDeleteConfirmed = (id?: string) => {
        if (!deleteData && !id) {
            Logger.error("[ArrayEditorInner] No delete data");
            return;
        }
        setValues(prevValues => {
            const newValues = prevValues.filter(value => value.id !== (deleteData?.id ?? id));
            onValueChanged(newValues);
            return newValues;
        });
        setFilteredIds(prev => prev.filter(x => x !== (deleteData?.id ?? id)));
        setInvalidInputs(prev => prev.filter(x => x.id !== (deleteData?.id ?? id)));
        setDeleteData(null);
        setConfirmDeleteVisible(false);
    }

    const onDelete = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
        if (props.readonly) {
            return;
        }

        setDeleteData({ id });
        
        const value = values.find(v => v.id == id);

        if (!value || isEmptyValue(value)) {
            onDeleteConfirmed(id);
            return;
        }

        setAnchorRef({current: e.currentTarget});
        setConfirmDeleteVisible(true);
    }

    const onCancelDelete = () => {
        setDeleteData(null);
        setConfirmDeleteVisible(false);
    }

    const onAddRow = () => {
        if (props.readonly) {
            return;
        }

        const newValue = getNewArrayValue(props.field, userInfo)

        setValues(prevValues => {
            const newValues = [
                ...prevValues,
                newValue,
            ];
            onValueChanged(newValues);
            return newValues;
        });

        setFilteredIds(prev => [...prev, newValue.id]);
    }

    const onChange = (id: string, value: ICrmArrayEdititingElement) => {
        setValues(prevValues => {
            const newValues = prevValues.map(e => e.id == id ? value : e);
            onValueChanged(newValues);
            return newValues;
        });
    }

    const onValidChanged = (id: string, fieldId: string, isValid: boolean) => {
        if (isValid) {
            setInvalidInputs(prev => prev.filter(x => x.id != id || x.fieldId != fieldId));
        }
        else {
            setInvalidInputs(prev => {
                if (prev.find(x => x.id == id && x.fieldId == fieldId) == null) {
                    return [...prev, {id, fieldId}];
                }
                return prev;
            });
        }
    }

    useEffect(() => {
        if (props.setIsValid) {
            props.setIsValid(invalidInputs.length == 0);
        }
    }, [invalidInputs.length == 0]);

    return <div className={props.reversed ? styles.narrowContainerReversed : styles.narrowContainer}>
        {props.field.filters?.[0] != null && values.length - filteredIds.length > 0 && <>
            <a onClick={() => setShowHidden(!showHidden)} className={styles.showHideButton}>
                {showHidden ? t("hide") : t("show")} {props.field.filters[0].caption} ({values.length - filteredIds.length})
            </a>
        </>}
        {values.map(value =>
            <ArrayItemInput key={value.id}
                tableId={props.tableId}
                value={value}
                field={props.field}
                onChange={onChange}
                onDelete={(e, id) => onDelete(e, id)}
                setIsValid={(f, v) => onValidChanged(value.id, f, v)}
                saveIfInvalid={props.saveIfInvalid}
                readonly={props.readonly}
                autocompleteValues={autocompleteValues}
                hidden={!showHidden && !filteredIds.includes(value.id)}
            />
        )}
        <button className={styles.addRowButton} onClick={onAddRow} disabled={props.readonly}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path d="M9 9V5.002a.999.999 0 1 1 2 0V9h3.998a.999.999 0 1 1 0 2H11v3.998a.999.999 0 1 1-2 0V11H5.002a.999.999 0 1 1 0-2H9z"></path>
            </svg>
            {i18n.t("add-row")}
        </button>
        <ConfirmDelete
            visible={confirmDeleteVisible}
            triggerRef={anchorRef}
            onCancel={onCancelDelete}
            onDelete={onDeleteConfirmed}
            placement="bottom-end"
        />
    </div>;
}

interface IArrayItemInputProps {
    value: ICrmArrayEdititingElement;
    field: ICrmField;
    onChange: (oldId: string, v: ICrmArrayEdititingElement) => void;
    onDelete: (e: React.MouseEvent<any>, id: string) => void;
    setIsValid?: (fieldId: string, isValid: boolean) => void;
    saveIfInvalid: boolean;
    autocompleteValues?: Record<string, string[]>;
    readonly?: boolean;
    tableId: string;
    hidden?: boolean;
}

function ArrayItemInput(props: IArrayItemInputProps) {
    const { value } = props;
    const [expanded, setExpanded] = useState<boolean>(value._isNew ?? false);
    const filteredFields = props.field.fields?.filter(x => !x.hidden);

    const handleExpanded = () => setExpanded(prev => !prev);

    const onChangeValueByField = (field: ICrmField, newValue: any) => {
        if (newValue != (value as any)[field.id] && (props.saveIfInvalid || InputValidator.validateByField(newValue, field))) {
            props.onChange(value.id, {
                ...value,
                // id: uuidv4(),
                [field.id]: newValue,
            });
        }
    }

    const onDelete = (e: React.MouseEvent<any>, id: string) => {
        e.stopPropagation();
        props.onDelete(e, id);
    };

    const getInputByField = (field: ICrmField) => {
        const fieldValue = (value as any)[field.id];
        const onChanged = (v: any) => onChangeValueByField(field, v);
        const setIsValid = (v: boolean) => {
            if (props.setIsValid) {
                props.setIsValid(field.id, v);
            }
        };

        const commonProps = {
            className: styles.valueInput,
            value: fieldValue,
            initialValue: fieldValue,
            placeholder: field.placeholder,
            autocompleteValues: props.autocompleteValues?.[field.id],
            onChanged: onChanged,
            setIsValid: setIsValid,
            readonly: props.readonly || field.readonly,
            validation: (value: any) => InputValidator.validateByField(value, field),
        }

        switch (field.viewType) {
            case CrmFieldViewType.Date:
            case CrmFieldViewType.DateTime:
                return <DateInput key={field.id} {...commonProps} withTime={field.viewType == CrmFieldViewType.DateTime}/>;
            case CrmFieldViewType.Combobox:
                return <ComboboxInput
                    key={field.id}
                    {...commonProps}
                    options={field.options!}
                />;
            case CrmFieldViewType.YesNo:
                return <CheckboxInput key={field.id} {...commonProps}/>;
            case CrmFieldViewType.Time:
                return <TimeInput key={field.id} {...commonProps}/>;
            case CrmFieldViewType.String:
                return <StringInput
                    key={field.id}
                    {...commonProps}
                    type="text"
                />;
            case CrmFieldViewType.MultiString:
                return <StringInput
                    key={field.id}
                    {...commonProps}
                    type="textarea"
                />;
            case CrmFieldViewType.Url:
                return <UrlInput key={field.id} {...commonProps}/>;
            case CrmFieldViewType.Decimal:
                return <DecimalInput key={field.id} {...commonProps}/>;
            case CrmFieldViewType.Phone:
                return <PhoneInput key={field.id} {...commonProps}/>;
            case CrmFieldViewType.Tags:
                return <TagsInput
                    key={field.id}
                    {...commonProps}
                    options={field.options}
                />;
            default:
                return <InvalidTypeInput key={field.id}
                    className={styles.valueInput}
                />;
        }
    }

    if (props.hidden) {
        return <></>;
    }

    return <div className={styles.narrowInputItem}>
        <div className={styles.narrowInputHeader} onClick={handleExpanded}>
            {expanded
                ? <>
                    <div className={styles.chevronContainer}>
                        <ChevronUpIcon/>
                    </div>
                    <div></div>
                </>
                : <>
                    <div className={styles.chevronContainer}>
                        <ChevronDownIcon/>
                    </div>
                    <div className={styles.arrayValue}>
                        <ArrayValueRenderer tableId={props.tableId} arrayValue={value} field={props.field}/>
                    </div>
                </>
            }
            <div onClick={e => onDelete(e, value.id)}>
                <TrashCanOutlineIcon/>
            </div>
        </div>
        {expanded && filteredFields?.map(field =>
            <div className={styles.narrowValueInputContainer} key={field.id}>
                <span>{field.caption}</span>
                <div>{getInputByField(field)}</div>
            </div>
        )}
    </div>;
}

function getArrayValues(values: any): ICrmArrayEdititingElement[] {
    if (Array.isArray(values)) {
        return values;
    }
    return [];
}

function getNewArrayValue(field: ICrmField, userInfo: ICrmUserInfo): ICrmArrayEdititingElement {
    const result: any = {
        id: uuidv4(),
        _isNew: true,
    };

    for (const innerField of field.fields ?? []) {
        const defaultValue = getDefaultValue(innerField, userInfo);

        if (defaultValue != null) {
            result[innerField.id] = defaultValue;
        }
    }

    return result;
}
