import { CommentsDateTime } from './CommentsDateTime';
import styles from './CommentsMessage.module.scss';
import { ReactNode, useEffect, useState } from 'react';
import { DeleteButtonWithConfirm } from '../ConfirmDelete/ConfirmDelete';
import { UserProfileIcon } from '../Icons/UserProfileIcon/UserProfileIcon';
import { CrmText } from '../CrmText';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { ICrmCommentMetadata } from '@core/Models/i-comment-message';
import { CommentOutlined, InfoCircleOutlined, MailOutlined, SoundOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { ReactComponent as CallIncomingIcon } from '@assets/Icons/call-incoming.svg';
import { ReactComponent as CallOutgoingIcon } from '@assets/Icons/call-outgoing.svg';
import { ReactComponent as CallIncomingMissedIcon } from '@assets/Icons/call-incoming-missed.svg';
import { ReactComponent as CallOutgoingMissedIcon } from '@assets/Icons/call-outgoing-missed.svg';
import { ReactComponent as EmailIncomingIcon } from '@assets/Icons/email-incoming.svg';
import { ReactComponent as EmailOutgoingIcon } from '@assets/Icons/email-outgoing.svg';
import { Button, Spin, Tooltip } from 'antd';
import { useAppSelector } from '@core/Redux/hooks';
import { selectTenantConfig, selectUserInfo } from '@core/Redux/store';
import { ICrmUserInfo } from '@core/Models/autogenerated/user.models';
import { userApiGetTenantUsers } from '@core/Api/user-api';
import { CallButton } from '../Inputs/PhoneInput/PhoneInput';

export interface ICommentsMessageProps {
    messageId: string;
    text: ReactNode;
    date: number;
    author?: string;
    metadata?: ICrmCommentMetadata;
    tenantUsers?: ICrmUserInfo[];
    onDelete?: (messageId: string) => void;
}

export const CommentsMessage = registerComponent(coreUiComponentDescriptions.CommentsMessage, _CommentsMessage);

function _CommentsMessage(props: ICommentsMessageProps) {
    const tentantConfig = useAppSelector(selectTenantConfig)!;
    const userInfo = useAppSelector(selectUserInfo)!;
    
    const commentTextElement = <p className={styles.text}>
        {typeof props.text == "string"
            ? <CrmText text={props.text}/>
            : props.text
        }
    </p>;

    // Call comment
    if (props.metadata?.type == "call") {
        const { call_type, status, user: userNumber, phone, link, duration } = props.metadata;

        const login = tentantConfig.telephony.providers.flatMap(provider => provider.users).find(user => user.extensionNumber === userNumber)?.login;
        const user = props.tenantUsers?.find(u => u.login === login);

        return <div className={styles.host}>
            <div className={styles.header}>
                {call_type == "in"
                    ? <>{status == "success"
                        ? <CallIncomingIcon className={styles.iconSvg}/>
                        : <CallIncomingMissedIcon className={styles.iconSvg}/>
                    }</>
                    : <>{status == "success"
                        ? <CallOutgoingIcon className={styles.iconSvg}/>
                        : <CallOutgoingMissedIcon className={styles.iconSvg}/>
                    }</>
                }
                {status == "success" ||
                    <span>{t("missed_call")}</span>
                }
                {call_type == "in"
                    ? <span>{t("incoming_call")}</span>
                    : <span>{t("outgoing_call")}</span>
                }
                <span>{formatDuration(duration)}</span>
                <span>|</span>
                <CommentsDateTime date={props.date} type='time'/>
            </div>
            <DeleteButtonWithConfirm onDelete={() => props.onDelete?.(props.messageId)}/>
            <div className={styles.callContainer}>
                <div className={styles.callContent}>
                    <div className={styles.callHeader}>
                        {t("user")}:&nbsp;{user == null || (!user.firstName && !user.lastName)
                            ? <span>{userNumber}</span> /* Если юзер не нашелся или у него не указаны имя и фамилия */
                            : <span>{`${user.firstName} ${user.lastName} (${userNumber})`}</span>
                        }
                        {" "}
                        {t("phone")}:&nbsp;<CallButton asString value={phone} userPhone={userInfo?.config?.phone}/>
                    </div>
                </div>
                <div className={styles.callButton}>
                    <Tooltip title={ link ? t("listen-call-record") : t("call-record-unavailable") }>
                        <Button icon={<SoundOutlined/>} href={link} target="_blank" disabled={!link}/>
                    </Tooltip>
                </div>
            </div>
        </div>;
    }

    // Email comment
    if (props.metadata?.type == "email") {
        const { email_type, from, to, subject } = props.metadata;

        return <div className={styles.host}>
            <div className={styles.header}>
                {email_type == "in"
                    ? <>
                        <EmailIncomingIcon className={styles.iconSvg}/>
                        <span>{t("incoming_email")}</span>
                    </>
                    : <>
                        <EmailOutgoingIcon className={styles.iconSvg}/>
                        <span>{t("outgoing_email")}</span>
                    </>
                }
                <span>|</span>
                <CommentsDateTime date={props.date} type='time'/>
            </div>
            <DeleteButtonWithConfirm onDelete={() => props.onDelete?.(props.messageId)}/>
            <div className={styles.emailContainer}>
                <div className={styles.emailContent}>
                    <div className={styles.emailHeader}>
                        {t("from_mail")}:&nbsp;<a href={`mailto:${from}`}>{from}</a>
                        {" "}
                        {t("to_mail")}:&nbsp;<a href={`mailto:${to}`}>{to}</a>
                    </div>
                    <div className={styles.emailInfo}>
                        {t("subject")}:&nbsp;{subject}
                    </div>
                </div>
            </div>
        </div>;
    }

    // System comment
    if (props.author == "System") {
        return <div className={styles.host}>
            <div className={styles.header}>
                <InfoCircleOutlined className={styles.icon}/>
                <span>Tabsmy CRM</span>
                <span>|</span>
                <CommentsDateTime date={props.date} type='time'/>
            </div>
            <DeleteButtonWithConfirm onDelete={() => props.onDelete?.(props.messageId)}/>
            {commentTextElement}
        </div>;
    }

    // Basic user comment
    return (
        <div className={styles.host}>
            <div className={styles.header}>
                <CommentOutlined className={styles.icon}/>
                <span>{t("comment")}</span>
                <span>|</span>
                <CommentsDateTime date={props.date} type='time'/>
            </div>
            <DeleteButtonWithConfirm onDelete={() => props.onDelete?.(props.messageId)}/>
            <div className={styles.userCommentContent}>
                <UserProfileIcon name={props.author}/>
                {commentTextElement}
            </div>
        </div>
    );
}

function formatDuration(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
  
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');
  
    if (hours > 0) {
      const paddedHours = String(hours).padStart(2, '0');
      return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    } else {
      return `${paddedMinutes}:${paddedSeconds}`;
    }
  }