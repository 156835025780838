import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import styles from './ConfirmDelete.module.scss';
import { MutableRefObject, useRef, useState } from 'react';
import Overlay from 'react-overlays/Overlay';
import { t } from 'i18next';
import { SecondaryButton } from '../Buttons/SecondaryButton';
import { registerComponent } from '@core/Plugins/pluginManager';
import { Placement } from '@popperjs/core';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { ReactComponent as TrashCanOutlineIcon } from "@assets/Icons/trash-can-outline-icon.svg";
import { Tooltip } from 'antd';

export interface IConfirmDeleteProps {
    triggerRef: MutableRefObject<any> | null;
    visible: boolean;
    onCancel: () => void;
    onDelete: () => void;
    placement?: Placement;
}

export const ConfirmDelete = registerComponent(coreUiComponentDescriptions.ConfirmDelete, _ConfirmDelete);

export function _ConfirmDelete(compProps: IConfirmDeleteProps) {
    const onDelete = (e: any) => {
        e.stopPropagation();
        compProps.onDelete();
    }

    const onCancel = (e: any) => {
        e.stopPropagation();
        compProps.onCancel();
    }

    return (<Overlay
        show={compProps.visible}
        rootClose
        flip={true}
        placement={compProps.placement ?? 'bottom-start'}
        onHide={compProps.onCancel}
        container={null}
        target={compProps.triggerRef}
    >
        {({props, arrowProps, placement}) => (
            <div {...props} className={styles.host}>
                <p>{t("ask_confirm_delete")}</p>
                
                <div className={styles.buttonsPanel}>
                    <SecondaryButton onClick={onDelete} danger>
                        {t("confirm_delete")}
                    </SecondaryButton>
                    <PrimaryButton onClick={onCancel}>
                        {t("cancel")}
                    </PrimaryButton>
                </div>
            </div>
        )}
    </Overlay>);
}

export interface IDeleteButtonWithConfirmProps {
    icon?: JSX.Element;
    onDelete: () => void;
}

export function DeleteButtonWithConfirm(props: IDeleteButtonWithConfirmProps) {
    const [isConfirmShow, setIsConfirmShow] = useState<boolean>(false);
    const deleteButtonRef = useRef<any>(null);

    const onDelete = () => {
        setIsConfirmShow(true);
    };

    const onCancelDelete = () => {
        setIsConfirmShow(false);
    };

    const onConfirmDelete = () => {
        props.onDelete();
        setIsConfirmShow(false);
    };

    return <>
        <Tooltip title={t("delete")}>
            <div className={styles.deleteButton} onClick={onDelete}>
                {props.icon
                    ? props.icon
                    : <TrashCanOutlineIcon ref={deleteButtonRef}/>
                }
            </div>
        </Tooltip>
        <ConfirmDelete
            triggerRef={deleteButtonRef}
            visible={isConfirmShow}
            onCancel={onCancelDelete}
            onDelete={onConfirmDelete}
        />
    </>;
}
