import { DateTime } from "luxon";
import style from "./CommentsDateTime.module.scss";
import { Tooltip } from "antd";
import i18n from "src/Locale/i18n";
import { t } from "i18next";

export interface ICommentsDateTimeProps {
    date: number; // date and time in seconds
    type?: "time" | "date" | "default";
    pretty?: boolean;
    color?: string;
}

export function CommentsDateTime(props: ICommentsDateTimeProps) {
    const now = DateTime.now();
    const dateTime = DateTime.fromSeconds(props.date);
    const time = dateTime.toFormat('HH:mm');
    const date = dateTime.toLocaleString();

    if (props.type == "time") {
        return <span className={style.host}>
            <Tooltip title={`${date} | ${time}`}>
                <span className={style.time} style={{ color: props.color }}>{time}</span>
            </Tooltip>
        </span>
    }
    if (props.type == "date") {
        if (props.pretty) {
            let formattedDate = "";
            
            if (dateTime.hasSame(now, 'day')) {
                formattedDate = t("today");
            } else if (dateTime.hasSame(now.plus({ days: 1 }), 'day')) {
                formattedDate = t("tomorrow");
            } else if (dateTime.hasSame(now.minus({ days: 1 }), 'day')) {
                formattedDate = t("yesterday");
            } else {
                if (dateTime.year === now.year) {
                    formattedDate = dateTime.setLocale(i18n.language).toFormat('d MMMM');
                } else {
                    formattedDate = dateTime.setLocale(i18n.language).toFormat('d MMMM yyyy');
                }
            }

            return <span className={style.host}>
            <span className={style.date} style={{ color: props.color }}>{formattedDate}</span>
        </span>
        }

        return <span className={style.host}>
            <span className={style.date} style={{ color: props.color }}>{date}</span>
        </span>
    }
    return (
        <span className={style.host}>
            <span className={style.date} style={{ color: props.color }}>{date}</span>
            &nbsp;|&nbsp;
            <span className={style.time} style={{ color: props.color }}>{time}</span>
        </span>
    );
}