import { CSSProperties, MouseEvent, MutableRefObject, useEffect, useState } from 'react';
import { registerComponent } from '@core/Plugins/pluginManager';
import { coreUiComponentDescriptions } from '@pluginShared/core-ui-api';
import { Button, Dropdown, Input, MenuProps, Select, Tooltip } from 'antd';
import { useTranslation } from "react-i18next";
import { PhoneFilled } from '@ant-design/icons'
import styles from './PhoneInput.module.scss';
import { useAppSelector } from '@core/Redux/hooks';
import { selectTenantConfig, selectUserInfo } from '@core/Redux/store';
import { extractStrictCleanPhones } from '@core/Helpers/phoneNumbers';
import { StringInput } from '../StringInput/StringInput';
import { ITelephonyProviderConfig, ITelephonyProviderUser } from '@core/Models/autogenerated/tenantConfig.models';
import { phoneApiMakeCall } from '@core/Api/phone-api';
import Logger from 'js-logger';

export interface IPhoneInputProps {
    initialValue?: string | null;
    placeholder?: string;
    autocompleteValues?: string[];
    onChanged?: (value: string | null) => void;
    onEnter?: () => void;
    onCall?: () => void;
    setIsValid?: (isValid: boolean) => void;
    className?: string;
    style?: CSSProperties;
    readonly?: boolean;
    disabled?: boolean;
    valueRef?: MutableRefObject<string | null>;
    autoFocus?: boolean;
    validation?: (value: string) => boolean;
    delayOnChanged?: boolean;
}

export interface ICallButtonProps {
    value?: string | null;
    userPhone: string | null;
    onCall?: (targetPhone: string, userPhone?: string) => void;
    asString?: boolean;
}

export const CallButton = registerComponent(coreUiComponentDescriptions.CallButton, _CallButton);

function _CallButton(props: ICallButtonProps) {
    const { t } = useTranslation();
    const config = useAppSelector(selectTenantConfig);
    const user = useAppSelector(selectUserInfo);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const allProviders = config?.telephony?.providers ?? [];
    let userProviders: {provider?: ITelephonyProviderConfig, user?: ITelephonyProviderUser}[] = [];
    for (let provider of allProviders) {
        const providerUser = provider.users.find(x => x.login == user?.login);
        if (providerUser != null) {
            userProviders.push({provider: provider, user: providerUser});
        }
    }

    if (userProviders.length == 0) {
        userProviders.push({});
    }


    const onCall = (e: MouseEvent<HTMLElement, unknown>, phone: string, provider: ITelephonyProviderConfig | undefined, user: ITelephonyProviderUser | undefined)=> {
        e.preventDefault();
        e.stopPropagation();

        Logger.debug(`Make call ${phone}, ${provider?.provider}, ${user?.extensionUserName}`);
        if (props.onCall != null) {
            if (props.userPhone != null)
                props.onCall(phone, user?.extensionNumber);
            else
                alert(t("user-phone-not-set"));
        } else {
            if (provider?.provider != null && provider?.provider != 'callto') {
                setButtonDisabled(true);
                phoneApiMakeCall({phone: phone, provider: provider.provider, user: user?.extensionUserName})
                    .catch(err => {
                        Logger.error(`Fail to make phone call to ${phone}, ${provider.provider}, ${user?.extensionUserName}: ${err.response?.data}`);
                        alert("Call error");
                    })
                    .finally(() => { setButtonDisabled(false); });
            } else {
                window.location.href = "callto:" + phone;
            }
        }
        return false;
    }

    if (props?.value == null) {
        return (
            <Tooltip placement="topLeft" title={t("input-not-contains-phones")}>
                {props.asString
                    ? <span className={styles.phoneSpan}>{props.value}</span>
                    : <Button className={styles.phoneButton} disabled={true}><PhoneFilled /></Button>
                }
            </Tooltip>
        );
    }

    const phones = extractStrictCleanPhones(props.value);

    let items: MenuProps['items'] = [];

    for (let provider of userProviders) {
        let providerString = "";
        if (provider.provider?.name != null)
            providerString = provider.provider?.name + ": ";

        for (let phone of phones) {
            items.push({
                key: providerString + phone,
                label: <button disabled={buttonDisabled} className={styles.dropdownButton} onClick={(e) => onCall(e, phone, provider.provider, provider.user)}>{providerString}{phone}</button>
            });
        }
    }

    if (items.length > 1) {
        return (
            <Dropdown menu={{ items }} disabled={buttonDisabled} placement="bottomRight">
                {props.asString
                    ? <span className={styles.phoneSpan}>{props.value}</span>
                    : <Button className={styles.phoneButton} disabled={buttonDisabled}><PhoneFilled /></Button>
                }
            </Dropdown>
        );
    } else if (items.length == 1) {
        const phone = phones[0];
        const provider = userProviders[0];
        return <>{props.asString
            ? <span className={styles.phoneSpan} onClick={(e) => buttonDisabled || onCall(e, phone, provider.provider, provider.user)}>{props.value}</span>
            : <Button className={styles.phoneButton} disabled={buttonDisabled} onClick={(e) => onCall(e, phone, provider.provider, provider.user)}><PhoneFilled /></Button>
        }</>
    } else {
        return (
            <Tooltip placement="topLeft" title={t("input-not-contains-phones")}>
                {props.asString
                    ? <span className={styles.phoneSpan}>{props.value}</span>
                    : <Button className={styles.phoneButton} disabled={true}><PhoneFilled /></Button>
                }
            </Tooltip>
        );
    }
}

export const PhoneInput = registerComponent(coreUiComponentDescriptions.PhoneInput, _PhoneInput);

function _PhoneInput(props: IPhoneInputProps) {
    const userInfo = useAppSelector(selectUserInfo);
    const [currentValue, setCurrentValue] = useState(props.initialValue);

    const onChanged = (value: string | null) => {
        setCurrentValue(value);
        props.onChanged?.(value);
    }

    const render = () => {

        return <div className={styles.phoneInput}>
            <StringInput
                {...props}
                onChanged={onChanged}
            />
            <CallButton value={currentValue} userPhone={userInfo?.config?.phone}/>
        </div>;
    }

    return render();
}
