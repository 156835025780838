import styles from './PageHeader.module.scss';

export interface IPageHeaderProps {
    withoutMargins?: boolean;
    children: any;
}

export function PageHeader(props: IPageHeaderProps) {
    return (<div className={styles.host} style={props.withoutMargins ? { margin: 0 } : undefined}>
        {props.children}
    </div>);
}
