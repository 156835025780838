import { extractShortenedPhoneNumbers } from '@core/Helpers/phoneNumbers';
import type { IEntity } from '@core/Models/i-entity';

export class SearchTools {
    private static readonly dateRegExp = /^((\d{1,2}[.-/]+){2}\d{2,4})|(\d{2,4}([.-/]+\d{1,2}){2})$/;
    private static readonly sep = "|";

    /**
     * Метод, извлекающий дату из текста. Если такой не нашлось, возращается `null`
     */
    private static extractDates(value: string) : string[] {
        // Если в тексте есть что-то похожее на дату
        if (this.dateRegExp.test(value)) {
            // Извлекаем числа из текста
            const dateParts = value.match(/\d+/g);
        
            // По идее, этого быть не должно, но на всякий случай проверяем, что всё извлеклось нормально
            if (!dateParts || dateParts.length < 3) {
                return [];
            }
            
            const [num1, num2, num3] = dateParts.map(Number);
            const variants = [
                `${num1}.${num2}.${num3}`, // mm.dd.yyyy
                `${num2}.${num1}.${num3}`, // dd.mm.yyyy
                `${num2}.${num3}.${num1}`, // yyyy.mm.dd
                `${num3}.${num2}.${num1}`, // yyyy.dd.mm
            ]
            let result: string[] = [];

            for (const variant of variants) {
                const date = new Date(variant).toLocaleDateString();

                if (date != "Invalid Date") {
                    result.push(date);
                }
            }

            return result;
        }
        
        return [];
    }

    private static uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/g;

    /**
     * Метод, проверяющий, является ли строка айдишником
     */
    private static isUuid(value: string): boolean {
        return this.uuidRegex.test(value);
    }

    private static emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

    /**
    * Метод, проверяющий, является ли строка адресом электронной почты
    */
    private static isEmail(value: string): boolean {
        const fullEmailRegex = new RegExp(`^${this.emailRegex.source}$`);
        return fullEmailRegex.test(value);
    }

    /**
     * Метод, извлекающий все электронные почты из строки
     */
    public static extractEmails(text: string): string[] {
        const emailRegex = new RegExp(this.emailRegex, 'g');
        const matches = text.match(emailRegex);
        return matches ? matches : [];
    }

    /**
     * Метод, преобразующий строку так, что он убирает ё, повторяющиеся пробелы и ковычки
     */
    private static normalizeString(value: any): string {
        if (value == null) {
            return "";
        }

        return value
            .toString()
            .toLowerCase()
            .replace(/ё/g, 'е')
            .replace(/\s+/g, ' ')
            .replace(/["'«»]/g, '')
            .trim()
    }

    /**
     * Метод, обрабатывающий строку поиска. Возвращает массив строк, по которым будет происходить поиск
     */
    public static normalizeTextToSearch(value: string) : string[] {
        value = this.normalizeString(value);
        let keywords = new Set<string>([value]);

        if (this.isUuid(value)) {
            return [value];
        }

        // if (this.isEmail(value)) {
        //     return [this.sep + value + this.sep];
        // }

        
        
        const extractedDates = this.extractDates(value);
        for (const date of extractedDates) {
            keywords.add(date);
        }

        const extractedPhoneNumbers = extractShortenedPhoneNumbers(value);
        for (const phone of extractedPhoneNumbers) {
            keywords.add(phone);
        }

        return Array.from(keywords);
    }

    /**
     * Метод, возвращающий строку с разделителями, содержащая значения какого-то объекта
     */
    private static objectValuesToString(object: {}): string {
        return Object.values(object)
            .map((e: any) => typeof e == "object" && e != null ? this.objectValuesToString(e) : /* this.normalizeString */(e))
            .join(this.sep);
    }

    private static objectValuesToStringWrapper(object: {}): string {
        const result = this.objectValuesToString(object);
        return this.sep + this.normalizeString(result) + this.sep;
    }

    /**
     * Метод, проверяющий, содержит ли Entity как подстроку одну из массива строк
     */
    public static isValuesInEntity(values: string[], entity: IEntity) : boolean {
        const keywordsString = SearchTools.objectValuesToStringWrapper(entity);
        return values.some(value => keywordsString.includes(value));
    }
}