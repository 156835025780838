import { ICrmCommentMessage } from "@core/Models/i-comment-message";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from 'uuid';

export function isCommentMessage(comment: any) {
    if (comment == null)
        return false;
    if (typeof comment !== 'object')
        return false;
    if (comment['id'] == null)
        return false;
    if (comment['date'] == null || typeof comment['date'] !== 'number')
        return false;
    if (comment['message'] == null || typeof comment['message'] !== 'string')
        return false;
    
    return true;
}

export function extractComments(value: any): ICrmCommentMessage[]{ 
    if (Array.isArray(value))
        return value.filter(isCommentMessage) as ICrmCommentMessage[];
    else if (typeof value === 'string') {
        let now = DateTime.now().toSeconds();
        return [{id: uuidv4(), date: now, message: value}];
    }
    else
        return [];
}

export function groupCommentsByDay(comments: ICrmCommentMessage[]): { day: number, comments: ICrmCommentMessage[] }[] {
    const grouped = new Map<number, ICrmCommentMessage[]>();

    comments.forEach(comment => {
        const date = new Date(comment.date * 1000);
        const dayStart = Math.floor(new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0, 0, 0, 0
        ).getTime() / 1000);

        if (!grouped.has(dayStart)) {
            grouped.set(dayStart, []);
        }

        grouped.get(dayStart)!.push(comment);
    });

    return Array.from(grouped.entries()).map(([day, comments]) => ({ day, comments }));
}
