import { useMemo } from "react";
import _ from 'lodash';
import { Page } from "@core/VisualComponents/Page/Page";
import { PageHeaderRow } from "@core/VisualComponents/Page/PageHeader/PageHeaderRow/PageHeaderRow";
import { PageTitleContainer } from "@core/VisualComponents/Page/PageHeader/PageTitleContainer/PageTitleContainer";
import { PageHeader } from "@core/VisualComponents/Page/PageHeader/PageHeader";
import { PageContent } from "@core/VisualComponents/Page/PageContent/PageContent";
import { Breadcrubs, IBreadcrumbs } from "@core/VisualComponents/Navigation/Breadcrumbs";
import { CommentsEditView } from "@core/VisualComponents/Comments/CommentsEditView";
import { ICrmField } from "@core/Models/autogenerated/tenantConfig.models";

interface ICommentsEditPageProps {
    field: ICrmField;
    tableId: string;
    entityId: string;

    breadcrumbs: IBreadcrumbs[];
}

export function CommentsEditPage(props: ICommentsEditPageProps) {
    const breadcrumbs: IBreadcrumbs[] = useMemo(() => 
        [ ...props.breadcrumbs,
            { url: "", caption : props.field.caption },
        ], []);
    
    return (
        <Page>
            <PageHeader withoutMargins>
                <PageHeaderRow>
                    <PageTitleContainer>
                        <Breadcrubs breadcrumbs={breadcrumbs} />
                    </PageTitleContainer>
                </PageHeaderRow>
            </PageHeader>
            <PageContent withoutMargins>
                <CommentsEditView field={props.field} tableId={props.tableId} entityId={props.entityId}/>
            </PageContent>
        </Page>);
}