import { ICrmCommentMessage } from "@core/Models/i-comment-message";
import { CSSProperties, Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from 'lodash';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import styles from './CommentsEditView.module.scss';
import { extractComments, groupCommentsByDay } from "src/Utils/commentsUtils";
import { useStore } from "@core/Stores/EventSourcingStoreProvider";
import { useAppSelector } from "@core/Redux/hooks";
import { selectUserInfo } from '@core/Redux/store';
import { CommentsMessage } from "@core/VisualComponents/Comments/CommentsMessage";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { ReactComponent as SendMessageIconSvg } from '@assets/Icons/send-message-icon.svg';
import { Divider, Input, Spin } from "antd";
import { ICrmField } from "@core/Models/autogenerated/tenantConfig.models";
import { t } from "i18next";
import { CommentsDateTime } from "./CommentsDateTime";
import { userApiGetTenantUsers } from "@core/Api/user-api";
import { ICrmUserInfo } from "@core/Models/autogenerated/user.models";

interface ICommentsEditViewProps {
    field: ICrmField;
    tableId: string;
    entityId: string;
    isReversed?: boolean;
    style?: CSSProperties;
}

export function CommentsEditView(props: ICommentsEditViewProps) {
    const [comments, setComments] = useState<ICrmCommentMessage[]>([]);
    const [tenantUsers, setTenantUsers] = useState<ICrmUserInfo[] | null>(null);
    const [isLoadingComments, setIsLoadingComments] = useState<boolean>(true);
    const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);
    const isLoading = isLoadingComments || isLoadingUsers;
    const chatDialogRef = useRef<HTMLInputElement>(null);
    const store = useStore(props.tableId);
    const userInfo = useAppSelector(selectUserInfo);

    const entity = store.useGet(props.entityId);

    useEffect(() => {
        let comments = entity?.data[props.field.id] ?? [];
        setComments(sortComments(extractComments(comments)));

        if (entity) {
            setIsLoadingComments(false);
        }
    }, [entity, props.field.id]);

    useEffect(() => {
        userApiGetTenantUsers({tenant: userInfo!.tenant}).then(users => {
            setTenantUsers(users);
            setIsLoadingUsers(false);
        });
    }, []);

    const sortComments = (comments: ICrmCommentMessage[]) => {
        return _.sortBy(comments, ['date', 'id']);
    }

    const groupedComments = useMemo(() => groupCommentsByDay(comments), [comments]);

    const storeComment = (comment: ICrmCommentMessage) => {
        let newComments = [...comments, comment];
        store.updateArray(props.entityId, props.field.id, comments, newComments);
    }

    const removeComment = useCallback((commentId: string) => {
        let newComments = comments.filter(x => x.id !== commentId);
        store.updateArray(props.entityId, props.field.id, comments, newComments);
    }, [comments, props.entityId, props.field.id]);

    const addMessage = (message: string) => {
        const commentItem = {
            id: uuidv4(),
            author: userInfo?.login,
            date: DateTime.utc().toUnixInteger(),
            message: message.trim(),
        } as ICrmCommentMessage;

        storeComment(commentItem);
    };

    const scrollDown = () => {
        chatDialogRef.current?.lastElementChild?.scrollIntoView({ block: props.isReversed ? "end" : "start"});
    };

    useEffect(() => {
        scrollDown();
    }, [comments.length, isLoading]);

    const renderMessage = (message: ICrmCommentMessage) => {
        const canDelete = userInfo?.role == "SuperUser" || userInfo?.login == message.author;
        // const onDelete = useCallback(() => removeComment(message), [removeComment, message]);

        return (
            <CommentsMessage
                key={message.id}
                messageId={message.id}
                text={message.message.trim()}
                date={message.date}
                author={message.author}
                metadata={message.metadata}
                tenantUsers={tenantUsers ?? undefined}
                onDelete={canDelete ? removeComment : undefined}
            />
        );
    };
    
    return (
        <div className={`${styles.chatWindow} ${props.isReversed && styles.reversed}`} style={props.style}>
            <div className={`${styles.chatDialog} ${props.isReversed && styles.reversed}`} ref={chatDialogRef}>
                {isLoading
                    ? <div className={styles.loadingContainer}>
                        <Spin size='large'/>
                        <span>{t("loading_status")}</span>
                    </div>
                    : groupedComments.map(group => <Fragment key={group.day}>
                        {props.isReversed || <Divider plain style={{ margin: 0 }}><CommentsDateTime date={group.day} type='date' pretty color="black"/></Divider>}
                        {group.comments.map((message) => renderMessage(message))}
                        {props.isReversed && <Divider plain style={{ margin: 0 }}><CommentsDateTime date={group.day} type='date' pretty color="black"/></Divider>}
                    </Fragment>)
                }
            </div>
            <CommentsInputArea
                placeholder={props.field.placeholder}
                onAddMessage={addMessage}
                disabled={props.field.readonly || isLoading}
            />
        </div>
    );
}

interface ICommentsInputAreaProps {
    placeholder?: string;
    onAddMessage: (text: string) => void;
    disabled: boolean;
}

function CommentsInputArea(props: ICommentsInputAreaProps) {
    const [inputValue, setInputValue] = useState<string>('');

    const handleKeyPress = (event: any) => {
        // Check if the pressed key is "Enter", and that the shift key is not held down.
        if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {
            // Prevent the default action to stop the form from submitting
            event.preventDefault();

            handleAddMessage();
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(e.target.value);
    }

    const handleAddMessage = () => {
        if (isAddMessageAllowed()) {
            props.onAddMessage(inputValue);
            setInputValue("");
        }
    };

    const isAddMessageAllowed = useCallback(() => {
        return !props.disabled && !!inputValue && inputValue.trim().length > 0;
    }, [inputValue, props.disabled]);

    return <div className={styles.inputContainer}>
        <Input.TextArea
            value={inputValue}
            onKeyDown={handleKeyPress}
            onChange={onChange}
            placeholder={props.placeholder}
            disabled={props.disabled}
            allowClear
            autoFocus
            autoSize={{maxRows: 4}}
        />
        <PrimaryButton style={{ padding: "6px 16px" }} onClick={handleAddMessage} disabled={props.disabled}>
            <SendMessageIconSvg style={{ height: "100%" }} className='addMessageIcon'/>
        </PrimaryButton>
    </div>
}