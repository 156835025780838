import { loadedActionButtons } from "@core/Plugins/pluginManager";
import { useAppSelector } from "@core/Redux/hooks";
import { selectPluginsState } from "@core/Redux/store";
import { IActionButton } from "@pluginShared/i-plugin-contract";
import { Fragment } from "react";

export interface IActionButtonsProps {
    tableId: string;
}

export function ActionButtons(props: IActionButtonsProps) {
    const pluginsState = useAppSelector(selectPluginsState);

    const actionButtons: IActionButton[] = pluginsState.modules
        .flatMap(module => 
            module.installation.actionButtons.map(x => loadedActionButtons[x.id ?? ""])
        )
        .filter(button => button.tableId == props.tableId)

    return <>{actionButtons.map(actionButton => <Fragment key={actionButton.id}><actionButton.func/></Fragment>)}</>;
}